@import "../../scss/variables";
@import '../../scss/functions';
@import '../../scss/mixins/direction';

.faq_component {
  .faqs {
    display: flex;
    flex-direction: column;

    .faq_component_item {
      border-bottom: 1px solid #BBB;

      .faq_title_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        gap: 10px;
        cursor: pointer;

        .faq_title {
          font-size: 16px;
          font-weight: 600;
          width: calc(100% - 55px);
        }

        span{
          width: 15px;
          height: 0;
          border: 1px solid black;

          &.plus{
            position: absolute !important;
            height: unset !important;
            right: 20px;
            transform: rotate(90deg);
            transition: .5s;
          }

          &.open{
            transform: rotate(0deg);
          }
        }
      }

      .faq_body {
        max-height: 0;
        padding: 0 20px;
        overflow: hidden;
        transition: all .5s linear;

        p{
          margin: 0;
        }
      }
    }
  }
}

.block-products {
  margin-bottom: 0;
}

.b-container {
  min-height: 0;
  margin-bottom: 0;
  margin-top: 60px;
  position: relative;

  h1, h2, h3, h4, h5, h6, span, p {
    word-break: break-word;
  }
}

.zg-grid-col {
  width: 100%;
  padding: 5px 10px;

  img {
    width: 100%;
  }
}

.zg-row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);

  > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  > .zg-dragforborder {
    box-sizing: border-box;
    background-color: $background-color_1;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
}

.builder_component {
  span:not(:global(.shared_title-line-fms__G_D1_)) {
    position: relative !important;
    height: 100% !important;
  }

  img {
    position: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    min-height: unset !important;
    width: 100% !important;
    height: 100% !important;
  }

  a {
    img {
      cursor: pointer !important;
    }
  }
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.home-product-container {
  margin: 47px auto !important;
}

@media screen and (max-width: 991px) {
  .home-product-container {
    max-width: 900px;
  }

  .zg-col-lg {
    flex: 1 0 0;
  }
}

@media screen and (min-width: 767px) {
  .zg-col-md {
    flex: 1 0 0;
  }
}

@media screen and (max-width: 600px) {
  .zg-col-sm {
    flex: 1 0 0;
  }
}