@import "../../scss/variables";
@import '../../scss/functions';
@import '../../scss/mixins/direction';

.post_page_title {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 40px;
  font-size: 40px;
  color: #000;
}

.post_body {
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: calc((100% - 48px) / 3) calc(((100% - 48px) / 3) * 2);
  gap: 24px;

  .post_filters {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .post_category_filters {
      padding-bottom: 32px;
      border-bottom: 1px solid #AAA;
    }

    .post_filter {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .post_filter_title {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-weight: 600;
          font-size: 18px;
          color: #000;
        }
      }

      .post_cat_filter_body {
        flex-direction: column;
        gap: 12px;

        &.desktop {
          display: flex;
        }

        &.mobile {
          display: none;
        }

        .post_filters_row {
          font-weight: 400;
          font-size: 18px;
          color: #303030;

          span:hover {
            cursor: pointer;
            border-bottom: 1px solid #0D47A1;
          }

          &.active {
            color: #0D47A1;
          }
        }
      }

      &.post_tags_filters {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .post_tag_filter_body {
          display: flex;
          flex-wrap: wrap;
          gap: 8px 11px;

          .post_filters_tag {
            padding: 7px 20px;
            background: #E8E8E8;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #000;
            cursor: pointer;

            &.active {
              background: #0D47A1;
              color: #FFF;
            }
          }
        }
      }
    }
  }

  .posts_list {
    display: grid;
    gap: 49px 24px;
    grid-template-columns: repeat(2, calc((100% - 24px) / 2));
  }

  .posts_pagination {
    padding-top: 38px;
    display: flex;
    justify-content: center;
  }
}

.posts_list {
  width: 100%;
  display: grid;
  gap: 49px 24px;
  grid-template-columns: repeat(3, calc((100% - 48px) / 3));
  margin-bottom: 80px;

  .post_list_item {
    width: 100%;

    .postCard {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .post_card_image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-height: 300px;
        margin-bottom: 0;
        border-radius: 3px;

        span {
          position: relative !important;

          img {
            aspect-ratio: 1/0.68;
            position: unset !important;
            width: 100% !important;
            height: auto !important;
            object-fit: contain;
          }
        }
      }

      .post_card_info {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        width: 100%;

        .post_card_name {
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          //height: 60px;
          width: 100%;
          font-size: 20px;
          line-height: 30px;
        }

        .post_card_read_data {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 4px;

          .post_card_date {
            font-size: 14px;
            line-height: 21px;
            color: #707070;
          }

          .post_card_read_more {
            font-size: 16px;
            line-height: 24px;
            color: #0D47A1;
          }
        }
      }
    }
  }
}

.empty_post_list {
  display: none;
}

.post_container {
  max-width: 990px;
  margin: 0 auto 80px;
  padding: 0 15px;

  .post-header__title {
    color: #000;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .post-header__meta {
    .post-header__meta-item {
      font-size: 14px;
      color: #707070;
      margin-bottom: 8px;
    }
  }

  .post__featured {
    width: 100%;
    height: 100%;
    margin-bottom: 32px;

    span {
      position: relative !important;

      img {
        position: relative !important;
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        aspect-ratio: 1/0.68;
        border-radius: 20px;
      }
    }
  }

  .tags_container {
    display: flex;
    gap: 8px 11px;
    padding-bottom: 24px;
    border-bottom: 1px solid #AAA;
    margin-bottom: 18px;

    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 20px;
      border-radius: 4px;
      background: #E8E8E8;
      color: #000;
      font-size: 12px;
    }
  }

  .typography {
    margin-bottom: 24px;
  }

  .share_container {
    display: flex;
    align-items: center;
    gap: 12px;

    .share_text {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .share_icons {
      display: flex;
      gap: 16px;
    }
  }
}

.products-view__empty {
  padding: 84px 0;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .products-view__empty-title {
    font-size: 24px;
    font-weight: $font-weight-medium;
  }

  .products-view__empty-subtitle {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 991px) {
  .post_page_title {
    margin-bottom: 40px;
    font-size: 32px;
  }

  .post_body {
    display: flex;
    flex-direction: column;

    .post_filters {
      .post_filter {
        .post_cat_filter_body {
          &.desktop {
            display: none;
          }

          &.mobile {
            display: flex;

            .cat_select {
              width: 50%;
              position: relative;

              .cat_button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #AAA;
                border-radius: 2px;
                padding: 5px 8px;
                font-size: 14px;
                line-height: 21px;
                color: #303030;

                .drop_down_arrow {
                  width: 21px;
                  height: 21px;
                  transition: all linear .3s;

                  &.opened {
                    transform: rotate(180deg);
                  }
                }
              }

              .cat_drop_down {
                width: 100%;
                position: absolute;
                top: calc(100% + 2px);
                background: #FFF;
                border: 1px solid #AAA;
                border-radius: 2px;

                .cat_option {
                  width: 100%;
                  padding: 5px 8px;
                  font-size: 14px;
                  border-bottom: 1px solid #EBEBEB;
                  color: #303030;

                  &:last-child {
                    border: none;
                  }

                  &.selected {
                    background: #EDEDED;
                    color: #0D47A1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .posts_list {
    grid-template-columns: repeat(2, calc((100% - 20px) / 2));
    gap: 32px 20px;
  }

  .post_container {
    margin-bottom: 64px;

    .post-header__title {
      font-size: 24px;
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 600px) {
  .post_page_title {
    margin-bottom: 18px;
    font-size: 24px;

    &.category_title {
      margin-bottom: 32px;
    }
  }

  .post_body {
    .post_filters {
      .post_filter {
        .post_cat_filter_body {
          &.mobile {
            .cat_select {
              width: 100%;
            }
          }
        }
      }
    }

    .posts_list {
      display: flex;
      flex-direction: column;
      gap: 23px;
    }
  }

  .posts_list {
    display: flex;
    flex-direction: column;
    gap: 23px;
  }

  .post_container {
    margin-bottom: 48px;

    .post-header__title {
      font-size: 20px;
      margin-bottom: 32px;
    }

    .tags_container {
      padding-bottom: 32px;
    }

    .share_container {
      .share_text {
        gap: 10px;
      }
    }
  }
}