.carousel {
  padding: 0 42px;

  :global(.slick-slider) {
    height: 100%;

    .slick_arrow_wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: calc(50% - 16px);
      z-index: 1;

      svg{
        pointer-events: none;
      }

      &:hover{
        opacity: 1;
        cursor: pointer;
      }

      &.prev{
        left: -32px;
      }

      &.next{
        right: -32px;
      }
    }

    :global(.slick-dots) {
      bottom: 16px;
      display: flex !important;
      justify-content: center;
      gap: 8px;

      li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #B5B5B5;
        margin: 0;

        button {
          display: none;
        }
      }

      :global(.slick-active){
        background: #121212;
      }
    }
  }
}

.carousel_card_wrapper {
  width: 100%;
  padding: 0 10px;

  .carousel_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 5px;
    overflow: hidden;

    .carousel_card_image_wrapper {
      width: 100%;

      img{
        width: 100%;
        aspect-ratio: 4/3;
        object-fit: cover;
      }
    }

    .carousel_card_content {
      min-height: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      gap: 10px;

      &.testimonials{
        min-height: 320px;
      }

      .carousel_card_title{
        text-align: center;
        font-size: 24px;
        font-weight: 800;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        cursor: pointer;
      }

      .carousel_card_description {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 7;
        cursor: pointer;
      }

      .carousel_card_button {
        max-width: 100%;
        background: black;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        line-height: normal;
        color: white;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
        cursor: pointer;
      }
    }
  }
}